<template>
  <div id="poule" class="loading">
    <div v-if="pouleList" class="section">
      <div class="poule-header">
        <h2 class="section-title h-custom-headline h3">Teams</h2>
        <div class="select">
          <select v-model="teamFilterType" class="poule-select">
            <option value="ZA">Zaal</option>
            <option value="VE">Veld (najaar)</option>
          </select>
        </div>
        <div class="select">
          <select v-model="activePoule" @change="switchTeam" class="poule-select">
            <option
              v-for="(team, index) in teams"
              :key="index"
              :value="team.poulecode"
            >{{ team.teamnaam }}</option>
          </select>
        </div>
      </div>

      <div class="poule-buttons">
        <button
          @click="activeTab = 'program'"
          :class="['poule-button', activeTab === 'program' ? 'active' : null]"
        >Programma</button>
        <button
          @click="activeTab = 'poolstand'"
          :class="['poule-button', activeTab === 'poolstand' ? 'active' : null]"
        >Poulestand</button>
      </div>

      <div class="poule-table poule-program" v-if="activeTab === 'program'">
        <table v-if="poule && poule.length > 0">
          <tr>
            <th>Speeldatum</th>
            <th>Aanvangstijd</th>
            <th class="align-left">Thuisteam</th>
            <th class="align-left">Uitteam</th>
            <th class="align-left">Accomodatie</th>
            <th class="align-left">Plaats</th>
          </tr>
          <tr v-for="(item, index) in poule" :key="'item' + index">
            <td>{{ item.datumopgemaakt }}</td>
            <td>{{ item.aanvangstijd }}</td>
            <td class="align-left">{{ item.thuisteam }}</td>
            <td class="align-left">{{ item.uitteam }}</td>
            <td class="align-left">{{ item.accommodatie }}</td>
            <td class="align-left">{{ item.plaats }}</td>
          </tr>
        </table>

        <p v-else>Geen resultaten beschikbaar</p>
      </div>

      <div class="poule-table poule-standings" v-if="activeTab === 'poolstand'">
        <table v-if="pouleStandings && pouleStandings.length > 0">
          <tr>
            <th>POS</th>
            <th class="align-left">TEAM</th>
            <th title="Gespeelde wedstrijden">G</th>
            <th title="Gewonnen">W</th>
            <th title="Gelijkspel">G</th>
            <th title="Verloren">V</th>
            <th title="Punten">PT</th>
            <th title="Doelsaldo">D</th>
            <th title="Doelpunten voor">+</th>
            <th title="Doelpunten tegen">-</th>
          </tr>
          <tr
            v-for="(team, index) in pouleStandings"
            :key="index"
            :class="[ team.eigenteam === 'true' ? 'current' : null]"
          >
            <td>
              <span class="team-pos">{{ team.positie }}</span>
            </td>
            <td class="align-left">{{ team.teamnaam }}</td>
            <td>{{ team.gespeeldewedstrijden }}</td>
            <td>{{ team.gewonnen }}</td>
            <td>{{ team.gelijk }}</td>
            <td>{{ team.verloren }}</td>
            <td>
              <strong>{{ team.punten }}</strong>
            </td>
            <td>{{ team.doelsaldo }}</td>
            <td>{{ team.doelpuntenvoor }}</td>
            <td>{{ team.doelpuntentegen }}</td>
          </tr>
        </table>

        <p v-else>Geen resultaten beschikbaar</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GamePoule",
  data() {
    return {
      loading: true,
      pouleList: null,
      activePoule: null,
      poule: null,
      pouleStandings: null,
      pouleResults: null,
      activeTab: "program",
      clientID: "Ot75z5ESLr",
      teamFilterType: "VE",
      teamList: null
    };
  },
  computed: {
    teams() {
      const that = this;
      if (!this.teamList) return;
      const teams = this.teamList.filter(function(el) {
        return el.kalespelsoort === that.teamFilterType;
      });
      this.activePoule = teams[0].poulecode;
      return teams;
    }
  },
  watch: {},
  mounted() {
    this.getTeams();
    this.getPouleList();
  },
  methods: {
    getTeams() {
      const parameters = "teams?";
      const name = "teamList";
      this.getData(parameters, name);
    },
    getPouleList() {
      const url =
        "https://data.sportlink.com/poulelijst?client_id=" + this.clientID;
      const that = this;
      fetch(url)
        .then(function(response) {
          return response.json();
        })
        .then(function(data) {
          that.pouleList = data;
          that.activePoule = data[0].poulecode;
          that.switchTeam();
        })
        .catch(function(error) {
          console.log(error);
        });
      document.getElementById("poule").classList.remove("loading");
    },
    switchTeam() {
      this.getPoule();
      this.getPouleStandings();
      this.getPouleResults();
    },
    getPoule() {
      const parameters =
        "poule-programma?poulecode=" +
        this.activePoule +
        "&aantaldagen=365&gebruiklokaleteamgegevens=NEE";
      const name = "poule";
      this.getData(parameters, name);
    },
    getPouleStandings() {
      const parameters =
        "poulestand?poulecode=" +
        this.activePoule +
        "&gebruiklokaleteamgegevens=NEE";
      const name = "pouleStandings";
      this.getData(parameters, name);
    },
    getPouleResults() {
      const parameters =
        "pouleuitslagen?poulecode=" +
        this.activePoule +
        "&gebruiklokaleteamgegevens=NEE";
      const name = "pouleResults";
      this.getData(parameters, name);
    },
    getData(parameters, name) {
      this.loading = true;
      const url =
        "https://data.sportlink.com/" +
        parameters +
        "&client_id=" +
        this.clientID;
      const that = this;
      fetch(url)
        .then(function(response) {
          return response.json();
        })
        .then(function(data) {
          that[name] = data;
        })
        .catch(function(error) {
          console.log(error);
        });

      if (
        document.getElementById("poule").classList &&
        document.getElementById("poule").classList.contains("loading")
      ) {
        document.getElementById("poule").classList.remove("loading");
      }
      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
#poule.loading {
  position: relative;
  padding-top: 56%;
  background: #f2f2f2;
  border-radius: 3px;
}

#poule.loading:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" display="block"><circle cx="50" cy="50" fill="none" stroke="%23ccc" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138" transform="rotate(95.809 50 50)"><animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"/></circle></svg>');
}

#poule.loading > * {
  display: none;
}

.align-left {
  text-align: left;
}

.poule-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.select {
  position: relative;
  margin-left: 8px;
}

.select:after {
  content: "";
  display: block;
  position: absolute;
  right: 8px;
  top: calc(50% - 4px);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 7px solid #666;
}

.poule-header .section-title {
  margin: 0 auto 8px 0;
}

.poule-buttons {
  display: flex;
  padding: 0 0 8px;
  margin: 0 0 16px;
  border-bottom: 1px solid #eee;
}

.poule-button,
.poule-select {
  display: flex;
  -webkit-appearance: none;
  outline: none;
  box-shadow: none;
  background: none;
  margin: 0;
  padding: 8px 12px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  color: #666;

  font-family: "Lato", Arial, Helvetica, sans-serif;
  font-size: 16px;
}

.poule-button:hover {
  background: #f2f2f2;
}

.poule-button.active {
  background: rgba(0, 153, 15, 0.7);
  color: #fff;
}

.poule-select {
  background: #f2f2f2;
  padding: 8px 32px 8px 12px;
}

.team-pos {
  display: flex;
  justify-content: center;
  max-width: 30px;
  padding: 5px;
  border-radius: 3px;
  background: #f2f2f2;
  color: #666;
}

.poule-table {
  overflow: scroll;
}

.poule-table .current td {
  background: rgba(0, 153, 15, 0.1);
  border: none;
  border-bottom: 1px solid #fff;
}

.poule-table .current td .team-pos {
  background: rgba(0, 153, 15, 0.7);
  color: #fff;
}

.poule-table table {
  width: 100%;
  text-align: center;
  border-left: 1px solid #f2f2f2;
  border-right: 1px solid #f2f2f2;
  border-collapse: collapse;
}

.poule-table th {
  padding: 5px 8px;
  background: #f2f2f2;
  color: #666;
  font-size: 12px;
  font-family: "Lato", Arial, Helvetica, sans-serif;
}

.poule-table td {
  padding: 5px 8px;
  border-bottom: 1px solid #f2f2f2;
  font-family: "Lato", Arial, Helvetica, sans-serif;
}

.poule-standings td:first-child,
.poule-standings th:first-child {
  max-width: 25px;
  padding: 5px 0 5px 8px;
}

@media (max-width: 768px) {
  .poule-header {
    flex-direction: column;
  }

  .poule-header > *,
  .poule-header select {
    width: 100%;
  }

  .select {
    margin: 0;
  }

  .poule-header > * {
    margin-bottom: 8px;
  }
}
</style>
