<template>
	<button
		@click="switchMenu"
		class="mobile-menu-button"
		:class="open ? 'open' : null"
	>
		<svg
			v-if="!open"
			aria-hidden="true"
			data-prefix="far"
			data-icon="bars"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 448 512"
			class="svg-inline--fa fa-bars fa-w-14 fa-3x"
		>
			<path
				fill="currentColor"
				d="M436 124H12c-6.627 0-12-5.373-12-12V80c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12z"
			/>
		</svg>
		<svg
			v-else
			aria-hidden="true"
			data-prefix="far"
			data-icon="times"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 320 512"
			class="svg-inline--fa fa-times fa-w-10 fa-3x"
		>
			<path
				fill="currentColor"
				d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"
			/>
		</svg>
	</button>
</template>

<script>
export default {
	name: "MobileMenuSwitch",
	data() {
		return {
			open: false,
		};
	},
	methods: {
		switchMenu() {
			const root = document.getElementsByTagName("html")[0];
			const nav = document.getElementById("nav");
			if (nav.classList && nav.classList.contains("open")) {
				this.open = false;
				nav.classList.remove("open");
				root.classList.remove("no-scroll");
			} else {
				this.open = true;
				nav.classList.add("open");
				root.classList.add("no-scroll");
			}
		},
	},
};
</script>

<style lang="scss">
.mobile-menu-button {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	z-index: 5;
	top: 7px;
	right: 7px;
	height: 42px;
	width: 42px;
	color: #fff;
	background: none;
	outline: none;
	box-shadow: none;
	-webkit-appearance: none;
	border: none;

	svg {
		height: 22px;
	}

	&.open {
		color: #000;
	}
}
</style>
