<template>
  <div id="results" class="loading" ref="resultsList">
    <h2 v-if="loading">Loading...</h2>

    <div v-if="results" class="section">
      <div class="result-list" v-if="results.length > 0">
        <div
          v-for="(result, index) in results"
          :key="index"
          :class="['result', result.uitslag ? null : 'result--date']"
        >
          <p v-if="!result.uitslag && result.status !== 'Afgelast'">{{ result }}</p>
          <div
            v-else
            :class="['result--info', rowActive === index ? 'active' : null]"
            @click="openRow(index, result.wedstrijdcode)"
          >
            <h2 class="team">{{ result.thuisteam }}</h2>
            <h2 class="points">{{ result.uitslag || result.status }}</h2>
            <h2 class="team">{{ result.uitteam }}</h2>
            <div v-if="rowActive === index" class="result__extra-info">
              <div v-if="moreLoading">Aan het laden...</div>
              <div v-if="!moreLoading" class="team">
                Gewonnen: {{ more.thuisteam.percentagegewonnen }}
                <br/>
                Thuis: {{ more.thuisteam.thuisgewonnen }}
                <br />
                Uit: {{ more.thuisteam.uitgewonnen }}
              </div>
              <p v-if="!moreLoading" class="time">
                {{ result.aanvangstijd }}
                <br />
                {{ result.accommodatie }}
              </p>
              <div v-if="!moreLoading" class="team">
                Gegewonnen: {{ more.uitteam.percentagegewonnen }}
                <br/>
                Thuis: {{ more.uitteam.thuisgewonnen }}
                <br />
                Uit: {{ more.uitteam.uitgewonnen }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <p v-else>Er zijn op dit moment geen resultaten beschikbaar.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "GameResults",
  data() {
    return {
      loading: true,
      amount: 10,
      showAmount: true,
      results: null,
      clientID: "Ot75z5ESLr",
      rowActive: null,
      lastDate: null,
      rowActive: null,
      more: null,
      moreLoading: true
    };
  },
  computed: {
    // resultList(){
    //   return this.results.map(this.mapResults);
    // }
  },
  watch: {},
  mounted() {
    this.getResults();
  },
  methods: {
    mapResults(result, index) {
      if (result.datumopgemaakt !== this.lastDate) {
        this.lastDate = result.datumopgemaakt;
        this.results.splice(index, 0, result.datumopgemaakt);
      }
      return result;
    },
    getResults() {
      const parameters =
        "uitslagen?gebruiklokaleteamgegevens=NEE&thuis=JA&uit=JA";
      const name = "results";
      this.getData(parameters, name);
    },
    getMore(wedstrijdcode) {
      const parameters =
        "wedstrijd-statistieken?wedstrijdcode=" + wedstrijdcode;
      const name = "more";
      this.getData(parameters, name);
    },
    getData(parameters, name) {
      this.loading = true;
      const url =
        "https://data.sportlink.com/" +
        parameters +
        "&client_id=" +
        this.clientID;
      const that = this;
      fetch(url)
        .then(function(response) {
          return response.json();
        })
        .then(function(data) {
          that[name] = data;
          that[name].map(that.mapResults);
          console.log(that[name]);
        })
        .catch(function(error) {
          console.log(error);
        })
        .finally(() => {
          document.getElementById("results").classList.remove("loading");
          this.loading = false;
          this.moreLoading = false;
        });
    },
    openRow(index, wedstrijdcode) {
      if (this.rowActive === index) {
        this.rowActive = null;
      } else {
        this.rowActive = index;
        this.moreLoading = true;
        this.getMore(wedstrijdcode);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#results.loading {
  position: relative;
  padding-top: 56%;
  background: #f2f2f2;
  border-radius: 3px;
}

#results.loading:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" display="block"><circle cx="50" cy="50" fill="none" stroke="%23ccc" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138" transform="rotate(95.809 50 50)"><animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"/></circle></svg>');
}

#results.loading > * {
  display: none;
}

.result-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 782px;
  font-family: "Lato", Arial, Helvetica, sans-serif;
}

.result-list h2,
.result-list p {
  margin: 0;
}

.result-list .result {
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.result-list .result:hover {
  background: rgba(0, 0, 0, 0.02);
}

.result-list .result:hover .points,
.result-list .result--info.active .points {
  background: rgba(0, 152, 14, 1);
  color: #fff;
}

.result-list .result--date {
  padding: 5px 8px;
  background: #eee;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

.result-list .result--info {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 8px 0;
  width: 100%;
}

.result-list .result--info.active {
  background: #eee;
}

.result-list .result--info * {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.result-list .result--info .team {
  flex: 2;
  font-weight: normal;
  font-size: 16px;
}

.result-list .result--info .points {
  flex: 1;
  padding: 5px 8px;
  background: #eee;
  font-size: 20px;
}

.result-list .result--info .time {
  flex: 1;
}

.result--info {
  display: flex;
}

.result__extra-info {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  opacity: 0.6;
}
</style>
