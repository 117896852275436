<template>
  <div id="program" class="loading" ref="program">
    <h2 v-if="loading">Loading...</h2>

    <div v-if="program" class="section">
      <table class="table table--program">
        <tr>
          <th>datum</th>
          <th>aanvangstijd</th>
          <!--<th>teamnaam</th>-->
          <th>thuisteam</th>
          <th>uitteam</th>

          <!--<th>scheidsrechter</th>
          <th>accommodatie</th>-->
          <th>plaats</th>
        </tr>
        <tbody v-for="(program_item, index) in programList" :key="index" @click="openRow(index)">
          <tr :class="[rowActive === index ? 'active' : null]">
            <td>{{ program_item.datum }}</td>
            <td>{{ program_item.aanvangstijd }}</td>
            <!--<td>{{ program_item.teamnaam }}</td>-->
            <td
              :class="{ bold : program_item.teamnaam === program_item.thuisteam }"
            >{{ program_item.thuisteam }}</td>
            <td
              :class="{ bold : program_item.teamnaam === program_item.uitteam }"
            >{{ program_item.uitteam }}</td>
            <td class="location">{{ program_item.plaats.toLowerCase() }}</td>
          </tr>
          <tr v-if="rowActive === index" class="table__info">
            <td colspan="5">
              <p>
                Scheidsrechter:
                <strong>{{ program_item.scheidsrechter ? program_item.scheidsrechter : "(nog) niet bekend" }}</strong>
                <br />Accommodatie:
                <strong>{{ program_item.accommodatie }}</strong>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <button @click="showmore" v-if="showAmount" class="show-more-button">Laat meer zien</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "GameSchedule",
  data() {
    return {
      loading: true,
      amount: 10,
      showAmount: true,
      program: null,
      clientID: "Ot75z5ESLr",
      rowActive: null
    };
  },
  computed: {
    programList() {
      return this.program.slice(1, this.amount);
    }
  },
  watch: {},
  mounted() {
    this.getProgram();
  },
  methods: {
    getProgram() {
      const parameters =
        "programma?gebruiklokaleteamgegevens=NEE&eigenwedstrijden=JA&thuis=JA&uit=JA";
      const name = "program";
      this.getData(parameters, name);
    },
    getData(parameters, name) {
      this.loading = true;
      const url =
        "https://data.sportlink.com/" +
        parameters +
        "&client_id=" +
        this.clientID;
      const that = this;
      fetch(url)
        .then(function(response) {
          return response.json();
        })
        .then(function(data) {
          that[name] = data;
        })
        .catch(function(error) {
          console.log(error);
        });
      this.loading = false;
      document.getElementById("program").classList.remove("loading");
    },
    formatDate(date) {
      date = new Date(date);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hours = date.getHours();
      const minutes = date.getMinutes();

      return hours + ":" + minutes + " " + day + "-" + month + "-" + year;
    },
    showmore() {
      this.amount = this.amount + 10;
      if (this.amount >= this.program.length) {
        this.showAmount = false;
      }
    },
    openRow(index) {
      if (this.rowActive === index) {
        this.rowActive = null;
      } else {
        this.rowActive = index;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#program.loading {
  position: relative;
  padding-top: 56%;
  background: #f2f2f2;
  border-radius: 3px;
}

#program.loading:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" display="block"><circle cx="50" cy="50" fill="none" stroke="%23ccc" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138" transform="rotate(95.809 50 50)"><animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"/></circle></svg>');
}

#program.loading > * {
  display: none;
}

.table {
  border-collapse: collapse;
  border-right: 1px solid #f2f2f2;
  border-bottom: 1px solid #eee;
  width: 100%;
}
.table th,
.table td {
  padding: 10px 10px;
  font-family: "Lato", Arial, Helvetica, sans-serif;
  border-top: 1px solid #eee;
  border-left: 1px solid #f2f2f2;
}

.table tbody {
  border-top: none;
}

.table tbody:nth-child(even) td {
  background: rgba(0, 0, 0, 0.03);
}

.table tr {
  cursor: pointer;
}

.table tr:hover td {
  background: #f2f2f2;
}

.table .active td {
  background: #f2f2f2;
}

.table__info td {
  background: #f2f2f2;
  padding-bottom: 20px;
}

td.location {
  text-transform: capitalize;
}

.show-more-button {
  -webkit-appearance: none;
  width: 100%;
  height: 42px;
  font-size: 14px;
  background: #f2f2f2;
  border: none;
  margin: 8px 0 0;
  border-radius: 5px;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.bold {
  font-weight: 900;
}

.section .table--program {
  text-align: left;
}
</style>
